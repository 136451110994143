<template>
  <div id="app">
    <loading v-if="loading" />
    <Header />
    <router-view  class="container"/>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "App",
  components: { Header, Loading },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    document.addEventListener("loader", this.handleLoader);
  },
  beforeDestroy() {
    document.removeEventListener("loader", this.handleLoader);
  },
  computed: {
    isLoading() {
      return this.$axios.state.isLoading;
    },
  },
  methods: {
    handleLoader(event) {
      this.loading = event.detail.loading;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 60px;
}
</style>
