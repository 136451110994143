<template>
  <div class="mt-16">
    <div class="flex justify-center items-center">
      <!-- <img src="@/assets/logo.png" class="p-8 h-64"/> -->
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Q8FKZiJM_uk?si=35ynZ-i4OoUJc4Kv?modestbranding=1&mute=1&showinfo=0&controls=1&autoplay=1"
        title="PDental Dental Cloud Management System"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
    <div
      class="flex justify-center items-center border-t-2 pt-4 text-xs text-gray-500 text-center mt-16"
    >
      <social />
    </div>
  </div>
</template>

<script>
import Social from "@/components/Social.vue";
export default {
  name: "HomePage",
  components: { Social },
};
</script>
