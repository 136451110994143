// axios
import axios from "axios";

const domain = "https://api.pdental.org/api/";
// const domain = "https://api-test.pdental.org/api/";
// const domain = "https://localhost:44344/api/";
// const domain = "http://localhost:5000/api/";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: domain, // replace with your API base URL
  timeout: 10000,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify config, add headers, etc.
    config.headers["Cid"] = config.params.Cid;
    // For example, add an authorization token
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.headers["X-Loading"] !== false) {
      document.dispatchEvent(
        new CustomEvent("loader", { detail: { loading: true } })
      );
    }
    return config;
  },
  (error) => {
    // Handle request error
    document.dispatchEvent(
      new CustomEvent("loader", { detail: { loading: false } })
    );
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle response data
    document.dispatchEvent(
      new CustomEvent("loader", { detail: { loading: false } })
    );
    if (response.status == 204) {
      return Promise.reject();
    }
    return response;
  },
  (error) => {
    // Handle response error
    document.dispatchEvent(
      new CustomEvent("loader", { detail: { loading: false } })
    );
    if (error.response && error.response.status === 401) {
      // For example, redirect to login if unauthorized
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
