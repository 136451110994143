<template>
  <header
    class="flex flex-row items-center justify-between max-w-full md:max-w-6xl px-2 md:px-6 pb-12 mx-auto md:flex-row"
  >
    <a href="/" class="text-indigo-900 z-10 active">
      <img src="@/assets/blankLogo.svg" class="w-12 py-8 md:py-0 g-image" />
    </a>
    <nav class="z-10">
      <Social />
    </nav>
  </header>
</template>
<script>
export default {
  name: "HeaderComponent",
  components: {
    Social: () => import("./Social.vue"),
  },
};
</script>
