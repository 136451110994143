
const imgFileTypes = [
  "jpg",
  "jpeg",
  "png",
  "svg",
  "jfif",
  "gif",
  "JPG",
  "JPEG",
  "PNG",
  "GIF",
  "SVG",
  "JFIF",
];
export const checkIfImage = (message) => {
  let msgExt = message.slice(((message.lastIndexOf(".") - 1) >>> 0) + 2);
  return imgFileTypes.includes(msgExt) ? true : false;
};

export const get_url_extension = (url) => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};
export const concatStrings = (oldString, newString) => {
  let result = "";
  if (oldString != null) {
    result = oldString.concat(",", newString);
  } else {
    result = oldString;
  }
  return result;
};

export const roundNumTwoDigits = (value) => {
  return Math.round((Number(value) + Number.EPSILON) * 100) / 100;
};

export const spreadQueries = (obj) => {
  const str = [];
  for (const p in obj)
    if (
      // eslint-disable-next-line
      obj.hasOwnProperty(p) &&
      p != "action" &&
      p != "pageTitle" &&
      p != "" &&
      p != null
    ) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      // str.push(p + "=" + obj[p]);
    }
  return str.join("&");
};

export const drawTeethNumber = (toothNum) => {
  // var toothNumberingSystem = store.state.AppActiveUser.toothNumberingSystem;
  // if (toothNumberingSystem == "FDI") {
  //   if (11 <= Number(toothNum) && Number(toothNum) <= 18) {
  //     return `<span class="toothClass"><span class="${
  //       vs.rtl ? "topLeft" : "topRight"
  //     }" >${toothNum.slice(1, 2)}</span></span>`; //.slice(1, 2);
  //   }
  //   if (51 <= Number(toothNum) && Number(toothNum) <= 55) {
  //     return `<span class="toothClass"><span class="${
  //       vs.rtl ? "topLeft" : "topRight"
  //     }" >${toothNum.slice(1, 2)}</span></span>`; //.slice(1, 2);
  //   }
  //   if (21 <= Number(toothNum) && Number(toothNum) <= 28) {
  //     return `<span class="toothClass" ><span class="${
  //       vs.rtl ? "topRight" : "topLeft"
  //     }" >${toothNum.slice(1, 2)}</span></span>`; //.slice(1, 2);
  //   }
  //   if (61 <= Number(toothNum) && Number(toothNum) <= 65) {
  //     return `<span class="toothClass"><span class="${
  //       vs.rtl ? "topRight" : "topLeft"
  //     }" >${toothNum.slice(1, 2)}</span></span>`; //.slice(1, 2);
  //   }
  //   if (41 <= Number(toothNum) && Number(toothNum) <= 48) {
  //     return `<span class="toothClass" ><span class="${
  //       vs.rtl ? "bottomLeft" : "bottomRight"
  //     }" >${toothNum.slice(1, 2)}</span></span>`; //.slice(1, 2);
  //   }
  //   if (81 <= Number(toothNum) && Number(toothNum) <= 85) {
  //     return `<span class="toothClass"><span class="${
  //       vs.rtl ? "bottomLeft" : "bottomRight"
  //     }" >${toothNum.slice(1, 2)}</span></span>`; //.slice(1, 2);
  //   }

  //   if (31 <= Number(toothNum) && Number(toothNum) <= 38) {
  //     return `<span class="toothClass" ><span class="${
  //       vs.rtl ? "bottomRight" : "bottomLeft"
  //     }" >${toothNum.slice(1, 2)}</span></span>`; //.slice(1, 2);
  //   }
  //   if (71 <= Number(toothNum) && Number(toothNum) <= 75) {
  //     return `<span class="toothClass"><span class="${
  //       vs.rtl ? "bottomRight" : "bottomLeft"
  //     }">${toothNum.slice(1, 2)}</span></span>`; //.slice(1, 2);
  //   }
  // } else {
    return toothNum;
  // }
};
